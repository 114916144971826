<template>
  <div class="vistar">
    <Teleport to="#header-buttons" />
    <div class="mt-2">
      <b-card no-body>
        <validation-observer ref="vistarForm">
          <b-row>
            <!--Network Details-->
            <b-col
              lg="12"
              md="12"
              xl="12"
            >
              <b-card-body>
                <b-row class="d-flex justify-content-between mx-1">
                  <span class="label mb-2">Network</span>
                  <span
                    v-if="false"
                    class="float-right"
                  >
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="primary"
                      size="sm"
                      @click="openViewAndUpdateCategoriesModal"
                    >
                      Manage Restrictions
                    </b-button>
                  </span>
                </b-row>
                <b-row>
                  <b-col
                    lg="6"
                    xl="6"
                    md="6"
                  >
                    <b-form-group
                      label="Network ID"
                      label-for="network-id"
                    >
                      <b-form-input
                        id="network-id"
                        v-model="device.network_id"
                        palceholder="Network ID"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="6"
                    md="6"
                    xl="6"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Vistar CPM"
                      vid="cpm"
                      rules="required|numeric|min_value:5"
                    >
                      <b-form-group
                        label="Screen CPM ($)"
                        label-for="cpm-floor"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="cpm"
                          v-model="device.cpm_floor_cents"
                          placeholder="Enter CPM ($)"
                          type="number"
                          :state="errors.length > 0 ? false:null"
                          vid="cpm"
                          @wheel.prevent
                          @touchmove.prevent
                          @scroll.prevent
                        />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-col>
            <b-col
              lg="12"
              xl="12"
              md="12"
            >
              <b-card-body
                title="Venue Details"
                class="pt-0"
              >
                <b-row>
                  <b-col
                    lg="6"
                    xl="6"
                    md="6"
                  >
                    <b-form-group
                      label="Venue Name"
                      label-for="venue-name"
                    >
                      <b-form-input
                        id="venue-name"
                        v-model="device.name"
                        placeholder="Enter Venue name"
                        variant="primary"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="6"
                    xl="6"
                    md="6"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      name="Venue Type"
                      vid="venue-type"
                      rules="required"
                    >
                      <b-form-group
                        label-for="venue-type"
                        label="Select Venue Type"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <v-select
                          v-model="device.venue_type"
                          input-id="venue-type"
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          vid="venue-type"
                          :options="device.venue_type_array"
                          :state="errors.length > 0 ? false:null"
                          label="name"
                          :reduce="venue_type => venue_type.vistar_venue_type_id"
                        />
                      </b-form-group>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </ValidationProvider>
                  </b-col>
                  <b-col
                    lg="6"
                    md="6"
                    xl="6"
                    class="mt-1"
                  >
                    <b-form-group
                      label="Venue ID"
                      label-for="partner-venue-id"
                    >
                      <b-form-input
                        id="partner-venue-id"
                        v-model="device.partner_venue_id"
                        palceholder="Venue ID"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="6"
                    xl="6"
                    md="6"
                    class="mt-1"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="required|numeric|min_value:5"
                      name="Static Duration Time"
                      vid="statictime"
                    >
                      <b-form-group
                        label="Static Duration Time(s)"
                        label-for="static-duration"
                      >
                        <b-form-input
                          id="static-duration"
                          v-model="device.static_duration_seconds"
                          class="form-control"
                          :state="errors.length > 0 ? false:null"
                          placeholder="0000"
                          type="number"
                          vid="statictime"
                          @wheel.prevent
                        />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </b-form-group>
                    </ValidationProvider>
                  </b-col>
                  <b-col
                    lg="6"
                    xl="6"
                    md="6"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="required|numeric|min_value:1|max_value:100"
                      name="Impressions"
                    >
                      <b-form-group
                        label="Impressions per Spot"
                        label-for="impressions"
                      >
                        <b-form-input
                          id="impressions"
                          v-model="device.impressions"
                          placeholder="Impressions per Spot"
                          :state="errors.length > 0 ? false:null"
                          type="number"
                          variant="primary"
                          @wheel="onWheel"
                        />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </b-form-group>
                    </validationprovider>
                  </b-col>
                  <b-col
                    lg="12"
                    xl="12"
                    md="12"
                    class="mt-1"
                  >
                    <h5 class="font-weight-bold">
                      Location & Time
                    </h5>
                  </b-col>
                  <b-col
                    lg="6"
                    xl="6"
                    md="6"
                  >
                    <b-form-group
                      label="Longitude"
                      label-for="longitude"
                    >
                      <b-form-input
                        id="longitude"
                        v-model="device.longitude"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="6"
                    xl="6"
                    md="6"
                  >
                    <b-form-group
                      label="Latitude"
                      label-for="latitude"
                    >
                      <b-form-input
                        id="latitude"
                        v-model="device.latitude"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="12"
                    xl="12"
                    md="12"
                  >
                    <b-form-group
                      label="Vistar Venue Address"
                      label-for="address"
                    >
                      <b-form-textarea
                        id="address"
                        v-model="device.address"
                        rows="0"
                        disabled
                        :placeholder="addressPlaceholder"
                        variant="primary"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="12"
                    xl="12"
                    md="12"
                    class="mt-1"
                  >
                    <h5
                      class="font-weight-bold"
                    >
                      Dimensions
                    </h5>
                  </b-col>
                  <b-col
                    lg="6"
                    md="6"
                    xl="6"
                    class="mt-1"
                  >
                    <b-form-group
                      label="Screen Height"
                      label-for="screen-height"
                      description="Units in Pixel"
                    >
                      <b-form-input
                        id="screen-height"
                        v-model="device.height_px"
                        placeholder="1920 PX"
                        variant="primary"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <b-col
                    lg="6"
                    md="6"
                    xl="6"
                    class="mt-1"
                  >
                    <b-form-group
                      label="Screen Width"
                      label-for="screen-width"
                      description="Units in Pixel"
                    >
                      <b-form-input
                        id="screen-width"
                        v-model="device.width_px"
                        placeholder="1600 PX"
                        variant="primary"
                        disabled
                      />
                    </b-form-group>
                  </b-col>
                  <div class="w-100" />
                  <b-col
                    lg="4"
                    xl="4"
                    md="4"
                  >
                    <b-form-checkbox
                      v-model="device.video_supported"
                      variant="primary"
                      name="allow-video"
                      :value="true"
                      :unchecked-value="false"
                    >Is Video supported?</b-form-checkbox>
                  </b-col>
                  <b-col
                    lg="4"
                    xl="4"
                    md="4"
                  >
                    <b-form-checkbox
                      v-model="device.static_supported"
                      variant="primary"
                      name="static-support"
                      :value="true"
                      :unchecked-value="false"
                    >
                      Is Image supported?
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <b-row align-h="between">
                  <b-col
                    class="mt-1"
                    cols="auto"
                  >
                    <span class="font-weight-bold pr-1">Vistar</span>
                    <b-form-checkbox
                      v-model="device.is_vistar_ad_show"
                      class="custom-control-primary check-text"
                      size="sm"
                      inline
                      switch
                    />
                  </b-col>
                  <b-col
                    class="mt-1"
                    cols="auto"
                    align-self="end"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mr-2"
                      @click="$router.go(-1)"
                    >
                      Cancel
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      filled
                      variant="primary"
                      :disabled="spinner"
                      @click="validationForm()"
                    >
                      <div
                        v-if="spinner"
                        class="spinner"
                      >
                        <b-spinner
                          small
                          class="d-inline-block"
                        />
                        <span class="sr-only">Loading...</span>
                      </div>
                      Submit
                    </b-button>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-col>
          </b-row>
        </validation-observer>
        <b-overlay
          :show="dataLoad"
          no-wrap
        />
      </b-card>
    </div>
    <VistarUpdateRestrictionModal
      ref="view-update-categories"
    />
  </div>
</template>
<script>
import Teleport from 'vue2-teleport'
import {
  BRow, BCol, BCard, BCardBody, BFormInput, BFormGroup, BFormTextarea,
  BFormCheckbox, BButton, BSpinner, BFormInvalidFeedback, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, between } from '@validations'
import Ripple from 'vue-ripple-directive'
import { min_value, numeric, max_value } from 'vee-validate/dist/rules'
import { showToast } from '@/common/global/functions'
import VistarUpdateRestrictionModal from '../devices-group/VistarUpdateRestrictionModal.vue'

export default {
  components: {
    BOverlay,
    ValidationObserver,
    ValidationProvider,
    BRow,
    BFormInvalidFeedback,
    BCol,
    BCard,
    Teleport,
    BCardBody,
    BFormInput,
    BFormGroup,
    vSelect,
    BFormTextarea,
    BFormCheckbox,
    BButton,
    BSpinner,
    VistarUpdateRestrictionModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      addressPlaceholder: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert, MO 65584-5678',
      required,
      spinner: false,
      between,
      numeric,
      min_value,
      max_value,
      dataLoad: false,
      device: {
        is_configure: false,
      },
    }
  },
  computed: {
    adminDevice() {
      return this.$store.getters['devices/getAdminDevice']
    },
  },
  async created() {
    // Initialize the device object with default values
    this.device = await this.$store.getters['devices/getVistarData']
    await this.reloadVistar(this.$route.params.userId, this.$route.params.deviceId)
  },
  methods: {
    onWheel(e) {
      e.target.blur()
    },
    async validationForm() {
      try {
        const success = await this.$refs.vistarForm.validate()
        if (success) {
          this.spinner = true
          // eslint-disable-next-line
          const mergedObject = {
            deviceId: this.$route.params.deviceId,
            sspType: 'vistar',
            cpm_floor_cents: this.device.cpm_floor_cents * 100,
            impressions: this.device.impressions,
            static_duration_seconds: this.device.static_duration_seconds,
            static_supported: this.device.static_supported,
            video_supported: this.device.video_supported,
            address: this.device.address,
            is_vistar_update: this.device.is_vistar_update,
            is_vistar_ad_show: this.device.is_vistar_ad_show,
            network_id: this.device.network_id,
            name: this.device.name,
            partner_venue_id: this.device.partner_venue_id,
            longitude: this.device.longitude,
            latitude: this.device.latitude,
            width_px: this.device.width_px,
            height_px: this.device.height_px,
            venue_type_id: this.device.venue_type,
          }
          const { userId } = this.$route.params
          const formData = JSON.stringify(mergedObject)
          await this.$store.dispatch('devices/vistarConfigure', { payload: formData, userId })
          await this.$router.push({
            name: 'single-device-admin',
            params: {
              deviceId: this.$route.params.deviceId,
              userId: this.$route.params.userId,
              deviceName: this.$route.params.deviceName,
            },
          })
        } else {
          this.spinner = false
          this.$swal('Enter valid data')
        }
      } catch ({
        response: {
          data: {
            statusCode,
            message,
          },
        },
      }) {
        if (typeof message === 'string') {
          this.$swal(message)
        } else {
          this.$swal(message[0].toString())
        }
      } finally {
        this.spinner = false
      }
    },
    async reloadVistar(userId, deviceId) {
      this.dataLoad = true
      const sspType = 'vistar'
      try {
        const success = await this.$store.dispatch('devices/getDeviceSSpData', { deviceId, sspType, userId })
        if (success) {
          showToast('Vistar', 'Fetch Vistar data successfully', 'success')
          this.device = this.$store.getters['devices/getVistarData']
          this.dataLoad = false
        }
      } catch ({
        response: {
          data: {
            statusCode,
            message,
          },
        },
      }) {
        if (statusCode === 500) {
          showToast('Internal Network Error', message.toString(), 'danger')
        } else {
          showToast('Authorization Error', message.toString(), 'danger')
        }
        this.dataLoad = false
      }
    },
    openViewAndUpdateCategoriesModal() {
      this.$refs['view-update-categories'].showOpTimeModal()
    },
  },
}
</script>
<style  lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
.check-text{
  padding-top: 8px;
}
</style>
<style lang="scss" scoped>
.custom-control-primary ::v-deep
  label.custom-control-label {
    padding: 0px !important;
  }
</style>
